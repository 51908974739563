const TeamPosition = {
  Red: {
    top: "53",
    right: "810",
    color: "#D01349",
  },
  Green: {
    top: "495",
    right: "648",
    color: "#00a94f",
  },
  Blue: {
    top: "389",
    right: "77",
    color: "#005B96",
  },
  Yellow: {
    top: "95",
    right: "365",
    color: "#FCB813",
  },
  White: {
    top: "358",
    right: "1085",
    color: "#899196",
  },
  TeamLeader: {
    top: "702",
    right: "280",
    color: "#899196",
  },
};

export default TeamPosition;
