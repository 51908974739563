import React, { Fragment, useEffect, useState } from "react";
import DroppableField from "../Droppables/DroppableField";

// Images
import { ReactComponent as CompetenceSVG } from "../../assets/SVG/competence.svg";
import { ReactComponent as Satisfaction } from "../../assets/SVG/satisfaction_2.svg";
import { ReactComponent as Avatar } from "../../assets/SVG/avatar.svg";
import HappyFace from "../../assets/images/HappyFace.png";
import SadFace from "../../assets/images/SadFace.png";
import Profile from "./Profile";
import Translate from "../../utils/Translate";

// CSS
import "../../assets/css/single-circle.css";

export default function SingleCircle({
  canEdit,
  name,
  stateData,
  impactData,
  color,
  top,
  right,
  timeCoinsImpact,
}) {
  const [isWhite, setIsWhite] = useState(false);
  const [isGrey, setIsGrey] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  useEffect(() => {
    if (name === "TeamLeader") {
      setIsGrey(true);
    }
    if (name === "White") {
      setIsWhite(true);
    }
  }, [name]);

  const styles = () => {
    let styles = {
      top: `${top}px`,
      right: `${right}px`,
      backgroundColor: `${color}`,
      border: `5px solid ${color} `,
    };
    if (isWhite) {
      styles.backgroundColor = "white";
      styles.border = "5px solid white";
    }

    return styles;
  };
  const handleProfile = (e) => {
    e.preventDefault();
    setOpenProfile(true);
  };

  return (
    <div>
      <div
        className="circle"
        style={styles()}
        onClick={(e) => handleProfile(e)}
      >
        <div
          style={
            isWhite ? { border: "2px solid #8A9298", padding: "2px" } : null
          }
        >
          <div
            className={`avatarSVG ${name}`}
            style={
              isWhite
                ? { backgroundColor: "white" }
                : { backgroundColor: color }
            }
          >
            <Avatar />
          </div>
          <div
            className="rectangle"
            style={isWhite ? { border: "1px solid #8A9298" } : null}
          >
            <div>
              <Translate alias="Board" word="Awareness of role / function" />
              {impactData.awarenessOfRole !== 0 ? (
                <span
                  className={`impact-visual-dots ${
                    impactData.awarenessOfRole > 0 ? "green" : "red"
                  }`}
                >
                  {impactData.awarenessOfRole > 0 ? "+" : ""}
                  {impactData.awarenessOfRole}
                </span>
              ) : null}
            </div>
            <div className="rings">
              {[...Array(10)].map((x, y) => (
                <div
                  className={`ring ${
                    stateData.awarenessOfRole > y && "checked"
                  }`}
                  style={{ marginTop: y * -2.2, right: y * -8.5 }}
                />
              ))}
            </div>
          </div>

          <div className="rectangle" > 
            <div>
              <Translate alias="Board" word="Belongingness" />
              {impactData.belongingness !== 0 ? (
                <span
                  className={`ml-2 impact-visual-dots ${
                    impactData.belongingness > 0 ? "green" : "red"
                  }`}
                >
                  {impactData.belongingness > 0 ? "+" : ""}
                  {impactData.belongingness}
                </span>
              ) : null}
            </div>
            <div className="rings">
              {[...Array(10)].map((x, y) => (
                <div
                  className={`ring ${stateData.belongingness > y && "checked"}`}
                  style={{ marginTop: y * -2.2, right: y * -8.5 }}
                />
              ))}
            </div>
          </div>
          <div className="three-cols">
            <div className={isWhite ? "white" : null}>
              <span>
                <Translate alias="Board" word="Competence" />
              </span>
              <div className={`svg ${name}`}>
                <img
                  src={stateData.competence >= 0 ? HappyFace : SadFace}
                  alt=""
                  className="happy-face"
                />
                <span className="single-circle-value">
                  {impactData.competence !== 0 ? (
                    <span
                      className={
                        impactData.competence > 0
                          ? "impact-increase"
                          : "impact-decrease"
                      }
                    >
                      {impactData.competence > 0 ? "+" : ""}
                      {impactData.competence}
                    </span>
                  ) : null}
                  {stateData.competence}
                </span>
                <CompetenceSVG />
              </div>
            </div>
            <div className={isWhite ? "white" : null}>
              <Translate alias="Board" word="Satisfaction" />
              <div className={`smileySVG ${name} `}>
                <img
                  src={stateData.satisfaction >= 0 ? HappyFace : SadFace}
                  alt=""
                  className="happy-face"
                />
                <span className="single-circle-value">
                  {impactData.satisfaction !== 0 ? (
                    <span
                      className={
                        impactData.satisfaction > 0
                          ? "impact-increase"
                          : "impact-decrease"
                      }
                    >
                      {impactData.satisfaction > 0 ? "+" : ""}
                      {impactData.satisfaction}
                    </span>
                  ) : null}
                  {stateData.satisfaction}
                </span>
                <Satisfaction />
              </div>
            </div>
            <div className={isWhite ? "white" : null}>
              <Translate alias="Board" word="Time" />
              <Fragment>
                <DroppableField
                  // teamContainer={true}
                  canEdit={canEdit}
                  alias={"Teams"}
                  name={name}
                  type={"time"}
                  data={stateData.time}
                  timeCoinsImpact={timeCoinsImpact}
                />
              </Fragment>
            </div>
            {isGrey && (
              <div>
                <Translate alias="Board" word="Budget" />
                <Fragment>
                  <DroppableField
                    // teamContainer={true}
                    canEdit={canEdit}
                    alias={"Teams"}
                    name={name}
                    type={"budget"}
                    data={stateData.budget}
                    timeCoinsImpact={timeCoinsImpact}
                  />
                </Fragment>
              </div>
            )}
          </div>
        </div>
      </div>
      {openProfile && (
        <Profile name={name} closeProfile={() => setOpenProfile(false)} />
      )}
    </div>
  );
}
