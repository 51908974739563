import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
    top: ${({ top }) => `${top}px`};
    right: ${({ right }) => `${right}px`};
    ::before {
      border-radius: ${({ rightSided }) =>
        rightSided ? "0 50% 50% 0" : "50% 0 0 50%"};

      transform: ${({ translateB4, angleB4, angle }) =>
        translateB4
          ? `rotate(${angleB4}deg) translate(${translateB4}px)`
          : `rotate(${angle}deg)`};
      border-${({ rightSided }) => (rightSided ? "right" : "left")}-color: ${({
  beforeBG,
  beforeH8,
}) => (beforeBG === "#BFC5C9" ? "white" : beforeBG)};
      height:  ${({ beforeH8 }) => `${beforeH8}px`};
    }
    ::after {
      border-radius: ${({ rightSided }) =>
        rightSided ? "0 50% 50% 0" : "50% 0 0 50%"};
      
      transform: ${({ angle, translate }) =>
        `rotate(${angle}deg) translate(${translate}px)`};
      border-${({ rightSided }) => (rightSided ? "right" : "left")}-color: ${({
  afterBG,
}) => (afterBG === "#BFC5C9" ? "white" : afterBG)};
      height:  ${({ afterH8 }) => `${afterH8}px`}; 
    }
  `;
const BeforeSvgStyle = styled.svg`
  svg > g > g > .cls-1 {
    fill: ${({ beforeBG }) => (beforeBG === "#BFC5C9" ? "white" : beforeBG)};
  }
`;
const AfterSvgStyle = styled.svg`
  svg > g > g > .cls-1 {
    fill: ${({ afterBG }) => (afterBG === "#BFC5C9" ? "white" : afterBG)};
  }
`;

export function StyledDivComp({ children, ...restProps }) {
  return <StyledDiv {...restProps}>{children}</StyledDiv>;
}

export function BeforeSvgStyleComp({ children, ...restProps }) {
  return <BeforeSvgStyle {...restProps}>{children}</BeforeSvgStyle>;
}

export function AfterSvgStyleComp({ children, ...restProps }) {
  return <AfterSvgStyle {...restProps}>{children}</AfterSvgStyle>;
}
