import React from "react";
import { ReactComponent as Arrow } from "../../assets/SVG/arrow.svg";
import HappyFace from "../../assets/images/HappyFace.png";
import SadFace from "../../assets/images/SadFace.png";
import {
  AfterSvgStyleComp,
  BeforeSvgStyleComp,
  StyledDivComp,
} from "./StyledComponents";
export default function RelationCard({
  angle,
  angleB4,
  rightSided,
  translate,
  top,
  right,
  beforeBG,
  afterBG,
  beforeH8,
  afterH8,
  translateB4,
  value,
}) {
  return (
    <StyledDivComp
      angle={angle}
      angleB4={angleB4}
      rightSided={rightSided}
      translate={translate}
      top={top}
      right={right}
      afterBG={afterBG}
      beforeBG={beforeBG}
      afterH8={afterH8}
      translateB4={translateB4}
      beforeH8={beforeH8}
      className="relationship"
    >
      <div className="relationship-card">
        <img
          src={33 >= 0 ? HappyFace : SadFace}
          alt=""
          className="happy-face"
        />
        <div
          className="relation-value"
          style={value >= 0 ? { color: "#FCB813" } : { color: "#531225" }}
        >
          {value}
        </div>
        <AfterSvgStyleComp
          angle={angle}
          angleB4={angleB4}
          rightSided={rightSided}
          translate={translate}
          top={top}
          right={right}
          afterBG={afterBG}
          beforeBG={beforeBG}
          afterH8={afterH8}
          translateB4={translateB4}
          beforeH8={beforeH8}
          className="left-arrow"
        >
          <Arrow />
        </AfterSvgStyleComp>
        <BeforeSvgStyleComp
          angle={angle}
          angleB4={angleB4}
          rightSided={rightSided}
          translate={translate}
          top={top}
          right={right}
          afterBG={afterBG}
          beforeBG={beforeBG}
          afterH8={afterH8}
          translateB4={translateB4}
          beforeH8={beforeH8}
          className="right-arrow"
        >
          <Arrow />
        </BeforeSvgStyleComp>
        <span>Relationship</span>
      </div>
    </StyledDivComp>
  );
}
