import React, { useEffect, useState } from "react";

// Image
import { ReactComponent as CompetenceSVG } from "../../assets/SVG/competence.svg";
import { ReactComponent as Satisfaction } from "../../assets/SVG/satisfaction.svg";
import { ReactComponent as Return } from "../../assets/SVG/return.svg";
// import Translate from "../../utils/Translate";
import "../../assets/css/single-team.css";

export default function CenterBoardCard({
  name,
  reputation,
  sumCompetence,
  sumSatisfaction,
  returnValue,
  teamScore,
}) {
  return (
    <div className="mini-board-team">
      <div className="justify-content-start">
        <span className="team-name">{name}</span>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            fontSize: "10px",
            marginLeft: "10px",
          }}
        >
          <span>Overall Teamscore </span>
        </div>
        <div>
          <span style={{ fontSize: 24, color: "#FFFFFF" }}>
            {teamScore ? teamScore : 0}
          </span>
        </div>
      </div>
      <div>
        <div
          style={{
            fontSize: "10px",
            marginLeft: "10px",
          }}
        >
          <span>Internal Teamscore </span>
        </div>
        <div
          style={{
            marginRight: "10px",
            width: "55%",
          }}
          className="mini-board-rings ml-2"
        >
          {[...Array(8)].map((_, y) => (
            <div
              key={y}
              style={{
                marginTop: y * -2,
              }}
            >
              <div className={`ring ${reputation >= y + 1 && "ringChecked"}`} />
            </div>
          ))}
        </div>
      </div>
      <div>
        <div>
          <span>TEAM </span>
        </div>
        <div className="three-bars-modal justify-content-start">
          <div>
            <div className="svg-container">
              <span>{sumCompetence}</span>
              <div className="svgContainer ">
                <CompetenceSVG />
              </div>
            </div>

            <span style={{ fontSize: "10px" }}>Competence </span>
          </div>
          <div>
            <div className="svg-container">
              <span>{sumSatisfaction}</span>
              <div className="svgContainer ">
                <div style={{ fill: "gray" }}>
                  <Satisfaction />
                </div>
              </div>
            </div>

            <span style={{ fontSize: "10px" }}>Satisfaction </span>
          </div>
        </div>
      </div>

      <div>
        <div>
          <span>RETURN </span>
        </div>
        <div className="three-bars-modal justify-content-start">
          <div>
            <div className="svg-container w-48">
              <span>{returnValue}</span>
              <div className="svgContainer ">
                <Return />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
