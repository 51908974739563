const initState = {
  Teams: {
    TeamLeader: {
      awarenessOfRole: 0,
      lastAwarenessOfRole: 0,
      belongingness: 0,
      lastBelongingness: 0,
      competence: 0,
      lastCompetence: 0,
      satisfaction: 0,
      lastSatisfaction: 0,
      time: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
      budget: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
    },
    Blue: {
      awarenessOfRole: 0,
      lastAwarenessOfRole: 0,
      belongingness: 0,
      lastBelongingness: 0,
      competence: 0,
      lastCompetence: 0,
      satisfaction: 0,
      lastSatisfaction: 0,
      time: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
      budget: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
    },
    Green: {
      awarenessOfRole: 0,
      lastAwarenessOfRole: 0,
      belongingness: 0,
      lastBelongingness: 0,
      competence: 0,
      lastCompetence: 0,
      satisfaction: 0,
      lastSatisfaction: 0,
      time: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
      budget: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
    },
    Red: {
      awarenessOfRole: 0,
      lastAwarenessOfRole: 0,
      belongingness: 0,
      lastBelongingness: 0,
      competence: 0,
      lastCompetence: 0,
      satisfaction: 0,
      lastSatisfaction: 0,
      time: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
      budget: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
    },
    Yellow: {
      awarenessOfRole: 0,
      lastAwarenessOfRole: 0,
      belongingness: 0,
      lastBelongingness: 0,
      competence: 0,
      lastCompetence: 0,
      satisfaction: 0,
      lastSatisfaction: 0,
      time: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
      budget: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
    },
    White: {
      awarenessOfRole: 0,
      lastAwarenessOfRole: 0,
      belongingness: 0,
      lastBelongingness: 0,
      competence: 0,
      lastCompetence: 0,
      satisfaction: 0,
      lastSatisfaction: 0,
      time: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
      budget: {
        hasContainer: false,
        coins: 0,
        lastCoins: 0,
      },
    },
  },
  Relations: {
    TeamLeaderBlue: 0,
    TeamLeaderRed: 0,
    TeamLeaderGreen: 0,
    TeamLeaderYellow: 0,
    TeamLeaderWhite: 0,
    BlueRed: 0,
    BlueGreen: 0,
    BlueYellow: 0,
    BlueWhite: 0,
    RedGreen: 0,
    RedYellow: 0,
    RedWhite: 0,
    GreenYellow: 0,
    GreenWhite: 0,
    YellowWhite: 0,
  },
  Invested: {
    General: {
      hasContainer: false,
      budget: 0,
      time: 0,
    },
    Red: {
      hasContainer: false,
      budget: 0,
      time: 0,
    },
    Green: {
      hasContainer: false,
      budget: 0,
      time: 0,
    },
    Blue: {
      hasContainer: false,
      budget: 0,
      time: 0,
    },
    Yellow: {
      hasContainer: false,
      budget: 0,
      time: 0,
    },
    White: {
      hasContainer: false,
      budget: 0,
      time: 0,
    },
    TeamLeader: {
      hasContainer: false,
      budget: 0,
      time: 0,
    },
    Marketing: {
      hasContainer: false,
      budget: 0,
      time: 0,
    },
  },
};

export default initState;
