const RelationPosition = {
  TeamLeaderBlue: {
    angle: "41",
    angleB4: "14",
    afterBG: "#899196",
    beforeBG: "#005B96 ",
    translate: "-36",
    translateB4: "-33",
    afterH8: "219",
    top: "669",
    right: "92",
    rightSided: true,
  },
  TeamLeaderRed: {
    angle: "139",
    angleB4: "146",
    afterBG: "#D01349",
    beforeBG: "#899196",
    translate: "17",
    translateB4: "30",
    beforeH8: "240",
    afterH8: "490",
    top: "530",
    right: "515",
  },
  TeamLeaderGreen: {
    angle: "115",
    angleB4: "136",
    beforeBG: "#899196",
    afterBG: "#00a94f",
    translateB4: "3",
    translate: "-9",
    top: "688",
    right: "518",
  },
  TeamLeaderYellow: {
    angle: "165",
    angleB4: "162",
    afterBG: "#FCB813",
    beforeBG: "#899196",
    afterH8: "200",
    beforeH8: "328",
    translate: "6",
    translateB4: "14",
    top: "382",
    right: "350",
    rightSided: true,
  },
  TeamLeaderWhite: {
    angle: "126",
    angleB4: "104",
    afterBG: "#BFC5C9",
    beforeBG: "#899196",
    translate: "-2",
    translateB4: "6",
    afterH8: "350",
    beforeH8: "423",
    top: "730",
    right: "813",
    rightSided: true,
  },
  BlueRed: {
    angle: "126",
    angleB4: "100",
    beforeBG: "#005B96 ",
    afterBG: "#D01349",
    translate: "0",
    translateB4: "3",
    afterH8: "380",
    beforeH8: "276",
    top: "418",
    right: "477",
    rightSided: true,
  },
  BlueGreen: {
    angle: "89",
    angleB4: "75",
    beforeBG: "#005B96 ",
    afterBG: "#00a94f",
    translate: "-33",
    translateB4: "-13",
    afterH8: "250",
    beforeH8: "180",
    top: "612",
    right: "373",
    rightSided: true,
  },
  BlueYellow: {
    angle: "338",
    angleB4: "298",
    afterBG: "#005B96 ",
    beforeBG: "#FCB813",
    translate: "-7",
    translateB4: "-8",
    beforeH8: "230",
    afterH8: "140",
    top: "269",
    right: "144",
    rightSided: true,
  },
  BlueWhite: {
    angle: "85",
    angleB4: "97",
    beforeBG: "#005B96 ",
    afterBG: "#BFC5C9",
    translate: "-21",
    translateB4: "-2",
    beforeH8: "730",
    top: "440",
    right: "895",
  },
  RedGreen: {
    angle: "157",
    angleB4: "152",
    afterBG: "#D01349",
    beforeBG: "#00a94f",
    translate: "4",
    translateB4: "16",
    top: "383",
    right: "764",
    rightSided: true,
  },
  RedYellow: {
    angle: "89",
    angleB4: "111",
    afterBG: "#D01349",
    beforeBG: "#FCB813",
    translate: "-26",
    translateB4: "-15",
    afterH8: "200",
    top: "110",
    right: "615",
  },
  RedWhite: {
    angle: "383",
    angleB4: "53",
    beforeBG: "#D01349",
    afterBG: "#BFC5C9",
    translate: "-39",
    translateB4: "-26",
    top: "255",
    right: "1050",
  },
  GreenYellow: {
    angle: "28",
    angleB4: "59",
    beforeBG: "#FCB813",
    afterBG: "#00a94f",
    translate: "-41",
    translateB4: "-12",
    afterH8: "230",
    beforeH8: "111",
    top: "275",
    right: "586",
  },
  GreenWhite: {
    angle: "280",
    angleB4: "295",
    beforeBG: "#BFC5C9",
    afterBG: "#00a94f",
    translate: "23",
    translateB4: "7",
    top: "560",
    right: "895",
  },
  YellowWhite: {
    angle: "59",
    angleB4: "68",
    beforeBG: "#FCB813",
    afterBG: "#BFC5C9",
    translate: "-7",
    translateB4: "10",
    beforeH8: "430",
    top: "341",
    right: "895",
  },
};

export default RelationPosition;
