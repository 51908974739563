import React, { useState } from "react";

// Images
import checkListIcon from "../../assets/images/checklist-icon.png";
import documentIcon from "../../assets/images/document-icon.png";

export default function FixedRightBar() {
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isCheckListOpened, setCheckListOpened] = useState(false);

  function toggleFilesMenu() {
    if (isMenuOpened) {
      setMenuOpened(false);
      updateUI("FILES", "close");
    } else {
      setMenuOpened(true);
      setCheckListOpened(false);
      updateUI("FILES", "open");
    }
  }

  function toggleCheckListMenu() {
    if (isCheckListOpened) {
      setCheckListOpened(false);
      updateUI("CHECKLIST", "close");
    } else {
      setCheckListOpened(true);
      setMenuOpened(false);
      updateUI("CHECKLIST", "open");
    }
  }

  function updateUI(panelType, status) {
    const appContainer = document.getElementsByClassName("center-container");
    const filesContainer = document.getElementsByClassName("filesContainer");
    const checkListContainer = document.getElementsByClassName("checkListContainer");

    if (status === "close") {
      appContainer[0].classList.remove("moveRight");
      filesContainer[0].classList.remove("fullWidth");
      checkListContainer[0].classList.remove("fullWidth");
    } else {
      if (panelType === "FILES") {
        appContainer[0].classList.add("moveRight");
        window.scrollTo({
          left: 380,
          behavior: "smooth",
        });
        filesContainer[0].classList.add("fullWidth");
        checkListContainer[0].classList.remove("fullWidth");
      } else if (panelType === "CHECKLIST") {
        appContainer[0].classList.add("moveRight");
        window.scrollTo({
          left: 380,
          behavior: "smooth",
        });
        checkListContainer[0].classList.add("fullWidth");
        filesContainer[0].classList.remove("fullWidth");
      } else {
        this.setState({ isMenuOpened: false, isCheckListOpened: false }, () => {
          filesContainer[0].classList.remove("fullWidth");
          checkListContainer[0].classList.remove("fullWidth");
        });
      }
    }
  }
  return (
    <>
      <div className="files-library-btn">
        <div className="section" onClick={toggleCheckListMenu}>
          <div className="button checklist">
            <img alt="checkListIcon" src={checkListIcon} />
            <div className="text">Check List</div>
          </div>
        </div>
        <div className="section" onClick={toggleFilesMenu}>
          <div className="button">
            <img alt="documentIcon" src={documentIcon} />
            <div className="text">Files Library</div>
          </div>
        </div>
      </div>
      <div className="checkListContainer">
        <div className="files-header">
          <div className="files-header-logo">
            <img alt="checkListIcon" src={checkListIcon} />
            <div className="text">Check List</div>
          </div>
        </div>

        <div className="filesContainer">
          <div className="files-header">
            <div className="files-header-logo">
              <img alt="documentIcon" src={documentIcon} />
              <div className="text">Files Library</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
