import React, { useState } from "react";
import "../../assets/css/FixedBars.css";
import kitsIcon from "../../assets/images/kits-icon.png";
import Translate from "../../utils/Translate";

import MainDraggableContainer from "../Draggables/MainDraggableContainer";
import MainDroppableDelete from "../Droppables/MainDroppableDelete";

const FixedLeftBar = ({ canEdit }) => {
  const [isMenuOpened, setMenuOpened] = useState(false);

  const updateUI = (panelType, status) => {
    const appContainer = document.getElementsByClassName("center-container");
    const menuContainer = document.getElementsByClassName("kitContainer");
    const rightBar = document.getElementsByClassName("right-bar");

    if (status === "close") {
      appContainer[0].classList.remove("moveLeft");
      // rightBar[0].classList.remove("moveRight");
      menuContainer[0].classList.remove("fullWidth");
    } else {
      appContainer[0].classList.remove("moveRight");
      appContainer[0].classList.add("moveLeft");
      // rightBar[0].classList.add("moveRight");

      if (panelType === "MENU") {
        menuContainer[0].classList.add("fullWidth");
      } else {
        setMenuOpened(false);
      }
    }
  };

  const toggleMenu = () => {
    if (isMenuOpened) {
      setMenuOpened(false);
      updateUI("MENU", "close");
    } else {
      setMenuOpened(true);
      updateUI("MENU", "open");
    }
  };

  return (
    <>
      <div className="kits-objects-btn" onClick={toggleMenu}>
        <div className="button">
          <img alt="kits-icon" src={kitsIcon} />
          <div className="text">
            <Translate alias="Board" word="Team Table" />
          </div>{" "}
        </div>
      </div>
      <div className="kitContainer">
        <div className="kits-header">
          <div className="kits-header-logo">
            <img alt="kits-icon" src={kitsIcon} />
            <div className="text">
              <Translate alias="Board" word="Team Table" />
            </div>{" "}
          </div>
        </div>
        <div className="blur">
          <div className="object-container-div">
            <div className="container-text">
              <Translate alias="Board" word="Containers" />
            </div>{" "}
            <MainDraggableContainer canEdit={canEdit} />
          </div>

          <div className="kits-separator" />
          <div className="object-delete-div">
            <div className="container-text">
              <Translate alias="Board" word="Delete" />
            </div>{" "}
            <MainDroppableDelete canEdit={canEdit} />
            <div className="clearfix"></div>
          </div>
          <div className="kits-separator"></div>
          <div style={{ height: "30px" }}></div>
        </div>
      </div>
    </>
  );
};

export default FixedLeftBar;
