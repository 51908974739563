import React from "react";
import { Table } from "reactstrap";

// Images
import container from "../../assets/images/container-h.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewGoldCoinImg from "../../assets/images/dollar_coin_silver-preview.png";

// CSS
import "../../assets/css/ContainerModal.css";

const formatNumber = new Intl.NumberFormat("en-US");
export default function ContainerModal({ coins, type }) {
  let coinsList = {};
  const getCoinRow = (key, imgSrc, count, value) => {
    return (
      <tr key={key}>
        <td>
          <img src={imgSrc} className="prevCoinImg" alt="" />
        </td>
        <td>{count}</td>
        <td>{formatNumber.format(parseFloat(value) * count)}</td>
      </tr>
    );
  };
  Array(coins)
    .fill(type === "time" ? 1 : 10)
    .map((value) => {
      coinsList[`${value}`] = coinsList[`${value}`] ? coinsList[`${value}`] + 1 : 1;
    });

  let coinsRows = [];
  let totalCoinsValue = 0;
  let totalCoinsCount = 0;

  if (coinsList[`10`]) {
    totalCoinsValue += 1 * parseFloat(coinsList[`10`]);
    totalCoinsCount += parseFloat(coinsList[`10`]);

    coinsRows.push(getCoinRow(0, previewGoldCoinImg, coinsList[`10`], 1));
  }
  if (coinsList[`1`]) {
    totalCoinsValue += 1 * parseFloat(coinsList[`1`]);
    totalCoinsCount += parseFloat(coinsList[`1`]);

    coinsRows.push(getCoinRow(1, previewSilverCoinImg, coinsList[`1`], 1));
  }

  return (
    <>
      <div className="containerObjClassModal">
        <img className="objModelClass" src={container} alt="" />

        <div className="coins-container">
          <div className="helper"></div>
          {Array(coins)
            .fill(type === "time" ? 1 : 10)
            .map((value, index) => {
              const isLastCoin = coins.length === index + 1 && (index + 1) % 5 === 0 ? true : false;
              let coinClass = isLastCoin ? "last-coin " : "not-last-coin ";
              switch (value) {
                case 1:
                  coinClass += "gold";
                  break;
                case 10:
                  coinClass += "silver";
                  break;

                default:
                  break;
              }
              return <div className={["coin", coinClass].join(" ")} />;
            })}
        </div>
      </div>
      <div className="clearfix"></div>

      <div style={{ height: 20 }}></div>
      <Table striped bordered>
        <thead>
          <tr style={{ color: "white", backgroundColor: "#002955" }}>
            <th>Value</th>
            <th>Count</th>
            <th>{type === "time" ? "Time" : "Cash"}</th>
          </tr>
        </thead>
        <tbody>{coinsRows}</tbody>
        <tbody>
          <tr>
            <td className="bold-text">Total</td>
            <td className="bold-text">{totalCoinsCount}</td>
            <td className="bold-text">{formatNumber.format(totalCoinsValue)}</td>
          </tr>
        </tbody>
      </Table>
      <div className="horizontal-separator">&nbsp;</div>
    </>
  );
}
