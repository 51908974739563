import React from "react";

// Imgs
import { ReactComponent as Arrow } from "../../assets/SVG/arrow.svg";

const _MoneyInvestRow = ({ option }) => {

	return (
		<div>
			{
				option.SiminterculturalEventOptionMoneyInvests.map((money, index) => (
					<div key={ index }>
						<span style={{ textTransform: "capitalize" }}>{ money.character }</span>
						<span className="negative">
							<Arrow className="arrow" /> { money.amount }
						</span>
					</div>
				)
			)}
		</div>
	)
}

export default _MoneyInvestRow