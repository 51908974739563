const urls = {
  backendURL: process.env.REACT_APP_BACKEND_URL,
  socketEndpoint: process.env.REACT_APP_SOCKET_URL,
  authenticate: process.env.REACT_APP_BACKEND_URL + "/api/player/login",
  logout: process.env.REACT_APP_BACKEND_URL + "/api/authenticate",
  renameTeam: process.env.REACT_APP_BACKEND_URL + "/api/team/rename",
  activeCounter: process.env.REACT_APP_BACKEND_URL + "/api/counter/active",
  eventLink: process.env.REACT_APP_BACKEND_URL + "/api/simintercultural/active/event",
  fetchEvent:
    process.env.REACT_APP_BACKEND_URL + "/simintercultural/common-event/active",
  groupLink:
    process.env.REACT_APP_BACKEND_URL +
    "/api/active/simintercultural-customer-card-group",
  characterProfiles:
    process.env.REACT_APP_BACKEND_URL + "/api/simintercultural/character/profiles",
  eventPageTranslate:
    process.env.REACT_APP_BACKEND_URL + "/api/simintercultural/event-page-translate",
};

export default urls;
